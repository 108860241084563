import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '',
        component: AppMainComponent,
        children: [
          {path: '', loadComponent: () => import('./pages/rechtsfall/rechtsfall.component').then(module_ => module_.RechtsfallComponent)},
          {path: 'mietenkonto', loadComponent: () => import('./pages/mietenkonto/mietenkonto.component').then(module_ => module_.MietenkontoComponent)},
          {path: 'dokumente', loadComponent: () => import('./pages/dokument/dokument.component').then(module_ => module_.DokumentComponent)}
        ]
      },
    ], { scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
