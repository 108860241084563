import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig, Translation} from 'primeng/api';

const de_translation: Translation = {
  startsWith: 'Beginnt mit',
  contains: 'Beinhaltet',
  notContains: 'Beinhaltet nicht',
  endsWith: 'Endet mit',
  equals: 'gleicht',
  notEquals: 'gleicht nicht',
  noFilter: 'Kein Filter',
  lt: 'Kleiner als',
  lte: 'Kleiner gleich',
  gt: 'Größer als',
  gte: 'Größer gleich',
  is: 'Ist',
  isNot: 'Ist nicht',
  before: 'Vor',
  after: 'Nach',
  dateIs: 'Datum ist',
  dateIsNot: 'Datum ist nicht',
  dateBefore: 'Datum ist vor',
  dateAfter: 'Datum ist nach',
  clear: 'Leeren',
  apply: 'Anwenden',
  matchAll: 'Gleicht allem',
  matchAny: 'Gleicht einigem',
  addRule: 'Rolle hinzufügen',
  removeRule: 'Rolle löschen',
  accept: 'Akzeptieren',
  reject: 'Ablehnen',
  choose: 'Wählen',
  upload: 'Hochladen',
  cancel: 'Abbrechen',
  dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
  dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
  dayNamesMin: ['S', 'M', 'D', 'M ', 'D', 'F ', 'S'],
  monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
  monthNamesShort: ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
  dateFormat: 'dd.mm.yy',
  firstDayOfWeek: 1,
  today: 'Heute',
  weekHeader: 'Wo',
  weak: 'Schwach',
  medium: 'Mittel',
  strong: 'Stark',
  passwordPrompt: 'Geben Sie ein Passwort ein',
  emptyMessage: 'Keine Ergebnisse gefunden',
  emptyFilterMessage: 'Keine Ergebnisse gefunden',
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  menuMode = 'static';

  lightMenu = false;

  inputStyle = 'outlined';

  ripple: boolean;

  constructor(private primengConfig: PrimeNGConfig) {}

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.ripple = true;
    this.primengConfig.setTranslation(de_translation);
  }
}
