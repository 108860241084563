import {Component, OnDestroy} from '@angular/core';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {Subscription} from 'rxjs';
import {MenuItem} from 'primeng/api';
import {Router} from '@angular/router';


@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnDestroy {

  subscription: Subscription;

  items: MenuItem[];

  constructor(public app: AppComponent, public appMain: AppMainComponent, public router: Router) {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
