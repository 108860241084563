import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {KeycloakService} from 'keycloak-angular';
import {DataService} from './services/data.service';

@Component({
  selector: 'app-menu',
  template: `
    <div class="menu">
      <ul class="layout-menu">
        <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
      </ul>
    </div>
  `
})
export class AppMenuComponent implements OnInit {

  model: MenuItem[];

  constructor(private keycloakService: KeycloakService, private dataService: DataService) {}

  ngOnInit() {
    this.dataService.currentRechtsfall$.subscribe(rechtsfall => {
      this.model = [
        {label: 'Administration', icon: 'pi pi-cog', routerLink: ['/administration'], visible: this.visibleFor('administrator')},
        {label: 'Rechtsfälle', icon: 'fas fa-gavel', routerLink: ['/']},
        {label: 'Dokumente', icon: 'fas fa-file-pdf', routerLink: ['/dokumente']}
      ];

      if (rechtsfall) {
        this.model = [
          {label: 'Rechtsfälle', icon: 'fas fa-gavel', routerLink: ['/']},
          {label: `Mietenkonto ${rechtsfall.minamf1} ${rechtsfall.minamv1}`, icon: 'fas fa-file-alt', routerLink: ['/mietenkonto']},
          {label: 'Dokumente', icon: 'fas fa-file-pdf', routerLink: ['/dokumente']}
        ];
      }
    });
  }

  private visibleFor(roles?: string | string[]): boolean {
    if (roles === undefined || roles.length === 0) {
      return false;
    } else if (Array.isArray(roles)) {
      return this.keycloakService.getUserRoles().some(r => roles.includes(r));
    } else {
      return this.keycloakService.getUserRoles().includes(roles);
    }
  }
}
