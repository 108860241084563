import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, DoBootstrap, LOCALE_ID, NgModule, NgZone} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppRoutingModule} from './app-routing.module';

import {MenuService} from './app.menu.service';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {environment} from '../environments/environment';
import {AppConfigService, IAppConfig} from './app-config.service';
import {AppTopBarComponent} from './app.topbar.component';

import localeAt from '@angular/common/locales/de-AT';

registerLocaleData(localeAt);

const keycloakService = new KeycloakService();

export function initializeApp(appConfigService: AppConfigService) {
  return () => appConfigService.load().then((value: IAppConfig) => {
    if (typeof value?.eigenleistung_enabled === 'string') {
      value.eigenleistung_enabled = JSON.parse(value.eigenleistung_enabled);
    }
    Object.assign(environment, value);
  });
}

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        KeycloakAngularModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppTopBarComponent,
        AppMenuComponent,
        AppMenuitemComponent
    ],
    providers: [
        MenuService,
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: KeycloakService, useValue: keycloakService},
        {provide: LOCALE_ID, useValue: 'de-AT'},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
        {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfigService], multi: true}
    ],
  exports: [
    AppMenuitemComponent,
    AppTopBarComponent,
    AppMenuComponent
  ]
})
export class AppModule implements DoBootstrap {
  constructor(public ngZone: NgZone) {}

  ngDoBootstrap(app: { bootstrap: (argument0: typeof AppComponent) => void; }) {
    this.initWithKeycloak().then(() => app.bootstrap(AppComponent));
  }

  initWithKeycloak() {
    return new Promise<void>((resolve) => {
      this.ngZone.runOutsideAngular(() => {
        keycloakService
          .init(environment.keycloakOptions)
          .then(() => {
            resolve();
          })
          .catch((error) =>
            console.error('[ngDoBootstrap] init Keycloak failed', error),
          );
      });
    });
  }
}
